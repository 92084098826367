import { api } from '..'

export interface Season {
  _id: string
  name: string
  startDate: string
  endDate: string
  currentSeason: boolean
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
}

export interface QueryParams {
  offset: number
  limit: number
  search: string
}

type SeasonsResponse = Season[]

export const seasonsApi = api.injectEndpoints({
  endpoints: build => ({
    getSeasons: build.query<SeasonsResponse, QueryParams>({
      query: params => {
        const { offset, limit, search } = params
        return {
          url: 'seasons/',
          params: {
            offset,
            limit,
            search,
          },
        }
      },
      providesTags: ['seasons'],
    }),
    getSeason: build.query<Season, string>({
      query: id => `seasons/${id}`,
      providesTags: ['season'],
    }),
    addSeason: build.mutation({
      query: body => ({
        url: 'seasons',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['seasons', 'competitions'],
    }),
    updateSeason: build.mutation({
      query: body => ({
        url: `seasons/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['seasons', 'season', 'competitions'],
    }),
    deleteSeason: build.mutation({
      query: id => ({
        url: `seasons/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['seasons', 'competitions'],
    }),
  }),
})

export const {
  useGetSeasonsQuery,
  useGetSeasonQuery,
  useLazyGetSeasonsQuery,
  useAddSeasonMutation,
  useUpdateSeasonMutation,
  useDeleteSeasonMutation,
} = seasonsApi
