import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import Layout from 'manage-tritag/components/layout'
import Spinner from 'manage-tritag/components/loading/spinner'
import EnhancedTable from 'manage-tritag/components/table/enhanced-table'
import EnhancedTableFilter from 'manage-tritag/components/table/enhanced-table/enhanced-table-filter'
import {
  QueryParams,
  Season,
  useLazyGetSeasonsQuery,
} from 'manage-tritag/services/api/endpoints/seasons'
import { getFormattedDate } from 'manage-tritag/utils/date'
import { navigate } from 'gatsby'

interface HeadCell {
  disablePadding: boolean
  id: keyof Season
  label: string
  numeric: boolean
}

const SeasonPage = () => {
  const { user } = useAuth0()
  const [params, setParams] = useState<QueryParams>({
    offset: 0,
    limit: 100000,
    search: '',
  })
  const [trigger, result] = useLazyGetSeasonsQuery()
  const [isLoading, setIsLoading] = useState(true)
  const { isLoading: dataLoading, data: seasons } = result

  useEffect(() => {
    trigger(params)
  }, [params])

  useEffect(() => {
    if (user) {
      if (user['https://tritagrugby.com/user_metadata']) {
        if (user['https://tritagrugby.com/user_metadata'].activeRole) {
          if (
            ![1, 2].includes(
              user['https://tritagrugby.com/user_metadata'].activeRole.type,
            )
          ) {
            navigate('/')
          } else {
            setIsLoading(false)
          }
        }
      }
    }
  }, [user])

  const headCells: HeadCell[] = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'startDate',
      numeric: false,
      disablePadding: false,
      label: 'Start date',
    },
    {
      id: 'endDate',
      numeric: false,
      disablePadding: false,
      label: 'End date',
    },
    {
      id: 'currentSeason',
      numeric: false,
      disablePadding: false,
      label: 'Current season',
    },
  ]
  const bodyCells = ['name', 'startDate', 'endDate', 'currentSeason']
  return (
    <Layout label1="Seasons" url="/seasons">
      {isLoading || dataLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 145px);',
          }}
        >
          <Spinner size={40} color="#008174" />
        </Box>
      ) : (
        seasons && (
          <>
            <EnhancedTableFilter params={params} setParams={setParams} />
            <EnhancedTable
              tableTitle="Seasons"
              uniqueIdentifier="_id"
              rows={seasons.map((season, index) => ({
                ...season,
                id: index,
                startDate: getFormattedDate(new Date(season.startDate)),
                endDate: getFormattedDate(new Date(season.endDate)),
                currentSeason: season.currentSeason ? 'Yes' : 'No',
              }))}
              headCells={headCells}
              bodyCells={bodyCells}
            />
          </>
        )
      )}
    </Layout>
  )
}

export default withAuthenticationRequired(SeasonPage)
